import React from 'react'

import Layout from '../components/layout'
import { Link } from 'gatsby'
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import { JsonLd } from '../components/JsonLd'

import chambre_image_2 from '../images/chambre/votes_54.png'

class VotesDeputesPage extends React.Component {
  render() {
    return (
      <Layout>
      <Seo title="Que votent nos députés ?"
      description="Analyse des votes des députés à la chambre des représentants en Belgique"/>
      <Helmet>
        <meta name="keywords" content="vote, députés, chambre, Belgique" data-react-helmet="true"/>
        <JsonLd>
        {{
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            "headline": "Que votent nos députés ?",
            "keywords": "vote, députés, chambre, Belgique",
            "url": "https://www.pointdevuedata.be/votes-deputes/",
            "datePublished": "2021-05-01T00:00:00+00:00",
            "dateCreated": "2021-05-01T00:00:00+00:00",
            "author": {
                "@type": "Person",
                "name": "Matthieu Genicot",
                "email": "matthieu.genicot@gmail.com"
            },
            "publisher": {
                "@type": "Person",
                "name": "Matthieu Genicot",
                "email": "matthieu.genicot@gmail.com"
            }
        }}
        </JsonLd>
      </Helmet>
      <div id="main">
      <article className="post featured">
      <header className='major'>
        <span className="date">En cours</span>
        <h2><Link to="/votes-deputes/">Que votent nos<br />
        députés ?</Link></h2>
        <p>Développement d'un outil interactif pour permettre à tous d'accéder aux milliers de votes de nos députés,
        <br/>pour des histoires politiques fondées et pour des votes plus informés.</p>
      </header>
      <p>En suivant la campagne présidentielle américaine, je me suis rendu compte que
      les médias américains avaient pour habitude de vérifier les anciennes prises de position
      et votes des candidats sur diverses thématiques de campagne. Une habitude qui permet à chacun
      qui s'en donne la peine de savoir si les déclarations d'un candidat
      sont en accord avec ses actes passés.
      <br/> En Belgique, très peu de tout cela, et même les 'GPS politiques',
      ces tests pré-élections révélant nos affinités avec les différents partis,
      se basent sur des promesses de campagne et non sur ce que les partis ont réellement voté précédemment...
      Et pour cause: Si les votes nominatifs de nos députés sont bien repris sur le site de la chambre,
      ils se trouvent un peu perdus dans les annexes des comptes-rendu de la chambre et il
      est nécessaire d'éplucher des centaines de fichiers de dizaines de pages
      ainsi que les pages web de chaque loi concernée afin de faire ressortir les positions
      d'un député sur une certaine thématique.
      <br/> Bref, une information à mes yeux essentielle pour une vraie transparence politique, une information
      qui devrait être accessible et attrayante, 
      se trouve être totalement invisible à l'heure actuelle.</p>

      <p>C'est de là qu'a germé l'idée d'extraire et de rendre accessibles ces votes
      afin que tous, journalistes, chercheurs et citoyens, puissent
      aisément vérifier et comparer les actes de nos 150 députés à la chambre.</p>

      <p>
      <image className="image featured right">
      <img src={chambre_image_2} alt="Tableau des votes des députés belges"/>
      </image>
      Après avoir codé un script en python pour extraire automatiquement les votes nominatifs d’une part et
      les infos sur les lois concernées d’autre part, j'ai présenté le tout sous forme d'un tableau.
      Ce premier tableau, repris ci-contre, contient plus de 3200 votes
      pour les 150 députés composant la chambre lors de la législature complète 2014-2019.
      Les votes sont triés par thème, selon des mots clés
      renseignés sur le site de la chambre et un classement 'eurovoc' mis en place par l’UE, et les députés
      classés par parti politique.</p>

      <p>Après cette première étape, j'ai partagé un <a href="https://medium.com/@matthieu.genicot/analyse-du-paysage-politique-belge-tout-en-donn%C3%A9es-4a72506845e8" target="_blank" rel="noopener noreferrer">article medium</a> reprenant
      ma démarche dans l'espoir d'intéresser l'un ou l'autre journaliste motivé pour trouver un financement
      et monter un vrai projet d'outil interactif, intuitif, gratuit et pérenne.</p>

      <p><em>Plus d'infos à venir</em></p>

      <p></p>
      </article>
      </div>
      </Layout>
    )
  }
}
export default VotesDeputesPage